
const categories = [
    {
        id:"handphone",
    },
    {
        id:"earphone",
    },
    {
        id:"laptop",
    },
    {
        id:"smartwatch",
    }
]

export default categories;