

const DataNews = [
    {
      id: "e1",
      name: "Beats Studio Buds Review Indonesia (GIVEAWAY‼)",
      category: "earphone",
      link: require("../img/Media/Earphone/1.png")
    },
    {
      id: "h1",
      name: "Review realme 10 Pro+ Indonesia",
      category: "handphone",
      link: require("../img/Media/HP/1.png")
    },
    {
      id: "l4",
      name: "Laptop ini wajib jadi panutan buat laptop lain",
      category: "laptop",
      link: require("../img/Media/Laptop/4.png")
    }
  ];

  export default DataNews;