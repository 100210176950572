 
// var a = 99;

const products = [
    {id: "l4",
    idDetail: "/l4",
    watchingId: `watching:l4`,
    img: require("../img/Media/Laptop/4.png"),
    title: "Laptop ini wajib jadi panutan buat laptop lain.",
    category: "laptop",
    comment: 1.4+"K",
    views: 604+"K",
    likes: 15+"K",
    source: "https://www.youtube.com/embed/zGcLI46Gdnc",
    desc: "Kreatip.\n\nLink pembelian: https://tokopedia.link/68tB6Puuqvb\n\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "laptop",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "30 November 2022",
    duration: "9 min 19 detik",
    quality: "HD",
},

    {
        id: "e1",
        idDetail: "/e1",
        watchingId: "watching-1",
        img: require("../img/Media/Earphone/1.png"),
        title: "Beats Studio Buds Review Indonesia (GIVEAWAY‼)",
        category: "earphone",
        comment: 297,
        views: 192965,
        likes: 22+"K",
        source: "https://www.youtube.com/embed/pXKHa6Vo-Rs",
        desc: "Unboxing & Review Beats Studio Buds by Apple.\nTWS MFI (Made For iPhone) keluaran apple ini bisa menjadi opsi selain TWS high-end Apple AirPods Pro, Harga yang lebih murah dengan fitur Active Noise Cancelling & Transparency Mode membuat Beats Studio Buds ini layak untuk dicoba! Simak Review Selengkapnya pada video diatas ya! :)\n\nGIVEAWAY ALERT!!! - 1 Pcs Beats Studio Buds (BRAND NEW IN BOX)\n(Info & Persyaratan akan diumumkan di Instagram @belanjabosscom)\nIkutan Ya! Stay Tuned & Good Luck!\n\nSponsored by:\nBelanjaBoss.com\n\nWebsite: https://www.belanjaboss.com/\nInstagram: https://instagram.com/belanjabosscom?...\nWhatsapp: Bit.ly/bb_wa\n\n---------------------------\nBe Social With Me !\n-------------------------------\nInstagram : @indratechlife\nTwitter : @indratechlife\nBusiness Inquires : contact@itech.life\n\nThank You for watching, Please like and subscribe for more videos.",
        type: "earphone",
        creator: "iTechLife",
        subscribers: 449+"K",
        release: "17 Agustus 2021",
        duration: "14 min 14 detik",
        quality: "HD",
    },
    {
        id: "h2",
        idDetail: "/h2",
        watchingId: `watching:h2`,
        img: require("../img/Media/HP/2.png"),
        title: "Review POCO F4 GT Indonesia",
        category: "handphone",
        comment: 4+"K",
        views: 2.1+"M",
        likes: 52+"K",
        source: "https://www.youtube.com/embed/VuZQjOKb3LM",
        desc: "Ditunggu.\n\nLink pembelian F4 GT: https://tokopedia.link/uNyJjf0Yurb\n\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
        type: "handphone",
        creator: "GadgetIn",
        subscribers: 10.2+"M",
        release: "01 Juli 2022",
        duration: "11 min 29 detik",
        quality: "HD",
    },
    {
        id: "e2",
        idDetail: "/e2",
        watchingId: "watching-2",
        img: require("../img/Media/Earphone/2.png"),
        title: "JBL WAVE 100 TWS | Review Indonesia",
        category: "earphone",
        comment: 191,
        views: 69737,
        likes: 556,
        source: "https://www.youtube.com/embed/DmQDIuNKz04",
        desc: "JBL WAVE 100 TWS adalah merupakan TWS yang paling terjangkau di jajaran TWS dari JBL, namun uniknya TWS ini mampu memberikan pengalaman Audio yang sangat bagus, saksikan Review selengkapnya ya :)\n--------------------------------------------------------------------------------------------------\n\nBusiness inquiries : parwa.network@gmail.com\nMy Instagram : https://www.instagram.com/Parwa_Dinawan/\nParwa Dinawan Channel : https://www.youtube.com/channel/UChNm...\n\n--------------------------------------------------------------------------------------------------\nMusic : \n\nAfternoon Tea (extended) – Mona Wonderlick (No Copyright Music)\nhttps://www.youtube.com/watch?v=Qe9RP...\nMisfits (Instrumental) – RYYZN (No Copyright Music)\nhttps://www.youtube.com/watch?v=iSSp4...\n--------------------------------------------------------------------------------------------------\n\nPlease Like, Comment, Share, and Subscribe\n\nThank you :)\n#JBLWAVE100TWS",
        type: "earphone",
        creator: "Parwa Dinawan",
        subscribers: 437,
        release: "2 Februari 2022",
        duration: "8 min 49 detik",
        quality: "HD",

    },
    {
        id: "e3",
        idDetail: "/e3",
        watchingId: "watching-3",
        img: require("../img/Media/Earphone/3.png"),
        title: "NOKIA NGELUARIN TWS TERLALU OVER POWER DI HARGA 200an- NOKIA E3100 PLUS",
        category: "earphone",
        comment: 75,
        views: 19654,
        likes: 301,
        source: "https://www.youtube.com/embed/g7v71KO47Z8",
        desc: "Beli disini : https://tokopedia.link/Sl614BRU0vb\n\nkupon nokia:\nNOKI1MIRZA\nmin. 229.000\nmax cap 15.000\n\nBergabung dengan channel ini untuk mendapatkan akses ke berbagai keuntungan:\nhttps://www.youtube.com/channel/UCh6T...\n\nBeli Peralatan Live Streaming Game BEHALF disini :\n► TOKOPEDIA : http://bit.ly/3oUQ7mA\n► SHOPEE : http://bit.ly/2LsIw18\n► Konsultasi live streaming : https://sociabuzz.com/mirzakaizar/tribe\n► SUBSCRIBE: https://bit.ly/2SOL7R2\n► Donate Streaming: https://sociabuzz.com/mirzakaizar/donate\n► Instagram: https://www.instagram.com/mirzakaizar\n► Facebook: https://bit.ly/2EtRUfO\n\nGear Live Streaming Mirza Kaizar :\n► Device Game Mobile :\n-----Iphone 13 ProMax\n-----Ipad pro M1\n► Headset :\nGame FPS:\n-----HyperX Cloud 2 Wireless\n-----HyperX Cloud Flight Wireless\nGame Moba / Game Stories:\n-----Logitech Pro X wireless\n\n► Game Capture :\n-----BEHALF MKDCPRO+ http://bit.ly/3tXrg5D\n► Kabel MHL :\n----BEHALF Ride The Lightning : http://bit.ly/3awmClS\n\n► Facecam & Lighting : \nWebcam:\n----- BEHALF THE EYE http://bit.ly/3tyJI4j\nChatCam:\n----- Canon eos M50 + Sigma 16mm f1.4\nLighting Keylight:\n---- Godox P260C\nLighting Fill Light:\n---- Behalf Hit The Light Saber\nLighting RGB:\n---- Behalf Hit The Light Saber\n\n► Audio Setup & Treatment :\nMicrophone :\n----- Shure Sm7b\nAudio Interface:\n----- GoXLR Mini\nAcoustic Treatment:\n---- Peredam Suara Behalf Blackned\nBoom Arm :\n----- Thron Max Zoom Boom Arm\n\n► Mouse + Keyboard :\nMouse :\n------ Logitech G pro X Wireless + SteelSeries Qck XXL\nKeyboard\n----- Fantech Maxfit 67 + Akko Macaw\n\n► Greenscreen :\n----- Elgato Green Screeen Collapsible\n\n► Networking :\n----- Melsa 150 mbps + Tenda ac6",
        type: "earphone",
        creator: "Mirza Kaizar",
        subscribers: 83.2+"K",
        release: "28 Oktober 2022",
        duration: "11 min 39 detik",
        quality: "HD",

    },
    {
        id: "e4",
        idDetail: "/e4",
        watchingId: "watching-4",
        img: require("../img/Media/Earphone/4.png"),
        title: "TWS PREMIUM DARI SONY: SONY WF1000XM4",
        category: "earphone",
        comment: 497,
        views: 254+"K",
        likes: 3.3+"K",
        source: "https://www.youtube.com/embed/92dMkqlrfQI",
        desc: "Cek Produk di video ini:\nhttps://www.sony.co.id/microsite/tran...\n\nInfo lebih lengkap:\nhttps://www.sony.co.id/id/electronics...\n\nSetelah menonton, jangan lupa klik tombol subscribe diatas dan nyalakan lonceng untuk notifikasi video terbaru langsung dari HP anda!\n\nJangan lupa untuk follow akun Instagram Ridwan Hanif di @ridwanhr\nhttps://www.instagram.com/ridwanhr\n\nDan follow juga akun Instagram @autonetcare di\nhttps://www.instagram.com/autonetcare\n\nDan AutonetCare Detailing di\nhttps://www.instagram.com/autonetcare...\n\nKalau kamu ingin sewa mobil di jakarta bisa ke autonet rent car di\nhttp://www.autonetrentcar.com",
        type: "earphone",
        creator: "Mirza Kaizar",
        subscribers: 83.2+"K",
        release: "28 Oktober 2022",
        duration: "11 min 39 detik",
        quality: "HD",

    },
    {
        id: "h1",
        idDetail: "/h1",
        watchingId: `watching:h1`,
        img: require("../img/Media/HP/1.png"),
        title: "Review realme 10 Pro+ Indonesia",
        category: "handphone",
        comment: 3.3+"K",
        views: 2+"M",
        likes: 36+"K",
        source: "https://www.youtube.com/embed/H3RMHIOsWA4",
        desc: "Pede.\nLink pembelian realme 10 Pro+: https://buy.realme.com/id/goods/356\n\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nBeberapa toko HP yang oke (kejual puluhan ribu unit + rating tinggi):\nhttps://tokopedia.link/AKIOook8Qvb\nhttps://tokopedia.link/anYzx6q8Qvb\nhttps://tokopedia.link/iD40kiC8Qvb\nhttps://tokopedia.link/DP7QokS8Qvb\nhttps://tokopedia.link/pMWv2tU8Qvb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/ \nTwitter GadgetIn: https://twitter.com/DGadgetIn \nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
        type: "handphone",
        creator: "GadgetIn",
        subscribers: 10.2+"M",
        release: "13 Januari 2023",
        duration: "11 min 47 detik",
        quality: "HD",
    },
    
    {
        id: "h3",
        idDetail: "/h3",
        watchingId: `watching:h3`,
        img: require("../img/Media/HP/3.png"),
        title: "HP iPhone Paling Worth it di Beli Tahun 2023!",
        category: "handphone",
        comment: 343,
        views: 88.4+"K",
        likes: 1.3+"K",
        source: "https://www.youtube.com/embed/mnHgfKkVNI8",
        desc: "Apakah iPhone 11 dan iPhone Xr masih Worth it untuk di beli di tahun 2023 ?Harga iPhone di tahun 2023 ada yang mengalami penurunan dan ada juga yg mengalami kenaikan harga! Berikut iPhone yang paling worth it untuk di beli di tahun 2023.\n#iphone #2023 #tips #iamcherish\n\niPhone 2023\niPhone X\niPhone Xr\niPhone Xs\niPhone Xs Max\niPhone 11\niPhone 11 Pro\niPhone 11 Pro Max\niPhone 12 Mini\niPhone 12\niPhone 12 Pro\niPhone 12 Pro Max\niPhone 13\niPhone 13 Mini\niPhone 13 Pro\niPhone 13 Pro Max\nSemoga Video ini dapat bermanfaat ya buat temen2 diluar sana hehe\n-----------------------------\nBantu support channel ini dengan memberikan like, share dan subscribe 👍🏻👍🏻 Sampai ketemu di video tips & trick selanjutnya.\n------------------------------------------------------------------------------\nFOLLOW AKUN MEDIA SOCIAL iamcherish\n\nuntuk bisnis, email : CherishcomApple@gmail.com\nWebsite : http://www.iamcherish.com/\nTips & Tricks : http://instagram.com/_iamcherish\nConsignment : http://instagram.com/cherishcomapple/\n-----------------------------------------------------------------",
        type: "handphone",
        creator: "iamcherish Apple Pro",
        subscribers: 392+"K",
        release: "10 Januari 2023",
        duration: "16 min 14 detik",
        quality: "HD",
    },
    {id: "h4",
    idDetail: "/h4",
    watchingId: `watching:h4`,
    img: require("../img/Media/HP/4.png"),
    title: "Unboxing Samsung Galaxy S20 FE (Snapdragon) Indonesia!",
    category: "handphone",
    comment: 7+"K",
    views: 2.5+"M",
    likes: 46+"K",
    source: "https://www.youtube.com/embed/Uws4iXpuJ98",
    desc: "Harganya udah selesai fermentasi.\nHarga murah di Official Store: https://tokopedia.link/david-sos12-yt\n\nLink pembelian S20 FE:\n1. https://tokopedia.link/pv36BReEVvb\n2. https://tokopedia.link/3uQA5d7DVvb\n3. https://tokopedia.link/EHHUAhdEVvb\n4.  https://tokopedia.link/PK4c7EhEVvb\n5. https://tokopedia.link/vb5EGclEVvb\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\nBeberapa toko HP yang oke (kejual puluhan ribu unit + rating tinggi):\nhttps://tokopedia.link/AKIOook8Qvb\nhttps://tokopedia.link/anYzx6q8Qvb\nhttps://tokopedia.link/iD40kiC8Qvb\nhttps://tokopedia.link/DP7QokS8Qvb\nhttps://tokopedia.link/pMWv2tU8Qvb\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "handphone",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "21 Desember 2023",
    duration: "15 min 06 detik",
    quality: "HD",
},
{id: "l1",
    idDetail: "/l1",
    watchingId: `watching:l1`,
    img: require("../img/Media/Laptop/1.png"),
    title: "MacBook Air wajib sungkem sama laptop Windows satu ini...",
    category: "laptop",
    comment: 99,
    views: 21+"K",
    likes: 486,
    source: "https://www.youtube.com/embed/7Eaaejw0o5c",
    desc: 'Popularitas MacBook Air sebagai laptop tipis powerful emang gak diraguin lagi. Tapi makin ke sini positioning-nya makin terancam dengan kehadiran beberapa laptop Windows ultra tipis, salah satunya si Zenbook S13 Flip OLED..\nCek harga: https://tokopedia.link/0anStbgKBwb\n\nTas laptop Rivacase (kode promo "RIVADROID10") : https://tokopedia.link/nLkh6EqKBwb\n\n===================================\nThe team:\n- Romi Hidayat https://www.instagram.com/romihidayat27/\n- Mohamad Fahmi https://www.instagram.com/mfahmi182/\n- Ery Tri Kurniawan https://www.instagram.com/eritk_/\n- Eko Lannueardy https://www.instagram.com/lannueardy/\n- Felix Marthiaz https://www.instagram.com/awfulsushi/\n- Diah Ayu https://www.instagram.com/diahayuprmt/\n- Firmansyah \n\nMusik yang kita gunakan untuk video https://bit.ly/37rrQ2j\nJangan lupa follow Instagram DL https://instagram.com/droidlime/\nBaca artikel ke website https://www.droidlime.com\n\n#DroidLime',
    type: "laptop",
    creator: "DroidLime",
    subscribers: 874+"K",
    release: "1 5Januari 2023",
    duration: "6 min 21 detik",
    quality: "HD",
},
{id: "l2",
    idDetail: "/l2",
    watchingId: `watching:l2`,
    img: require("../img/Media/Laptop/2.png"),
    title: "Laptop paling LIAR yang pernah saya coba",
    category: "laptop",
    comment: 2+"K",
    views: 1.4+"M",
    likes: 32+"K",
    source: "https://www.youtube.com/embed/B5ZBOkpMewQ",
    desc: "Laptop lipat. Tapi semuanya juga dilipat...\n\nGratis Kopi Kapal Api Special Mix Gula Aren untuk 500 orang pertama!\n\nCaranya:\n\n1. Kunjungi website https://secangkirsemangat.id/redeem\n\n2. Isi data diri & Masukkan kode unik : DAVIDARENINAJA\n\n3. Paket Kopi Kapal Api Special Mix Gula Aren siap diantar ke rumahmu!\n\nLink pembelian Zenbook Fold: https://tokopedia.link/JlZWbtoRNvb\n\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "Laptop",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "15 Desember 2022",
    duration: "12 min 06 detik",
    quality: "HD",
},
{id: "hl3",
    idDetail: "/l3",
    watchingId: `watching:l3`,
    img: require("../img/Media/Laptop/3.png"),
    title: "Nyobain laptop Rp5 juta yang populer dari Asus, VivoBook 14 A420!",
    category: "laptop",
    comment: 5+"K",
    views: 2.5+"M",
    likes: 127+"K",
    source: "https://www.youtube.com/embed/R9pTTFLxIkc",
    desc: "BPJS banget.\n\nBuat dapetin produk produk kesehatan: http://tkp.me/GadgetinPeduliSehat\nHP bekas review biasanya saya jual disini: https://invol.co/clmats\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "Laptop",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "07 Maret 2020",
    duration: "9 min 0 detik",
    quality: "HD",
},
{id: "s1",
    idDetail: "/s1",
    watchingId: `watching:s1`,
    img: require("../img/Media/SmartWatch/1.png"),
    title: "Review Huawei Watch Fit 2",
    category: "smartwatch",
    comment: 1.2+"K",
    views: 2.6+"M",
    likes: 13+"K",
    source: "https://www.youtube.com/embed/rL_5Tsj0srk",
    desc: "Dikira cupu...\n\nInfo lebih lanjut di website Huawei: https://bit.ly/3zG8DII\n\nKalau mau beli Watch Fit 2: https://bit.ly/3GvREtR\nKalau mau beli GT 3 Pro: https://bit.ly/3zETYNH\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "smartwatch",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "15 Juni 2022",
    duration: "8 min 09 detik",
    quality: "HD",
},
{id: "s2",
    idDetail: "/s2",
    watchingId: `watching:s2`,
    img: require("../img/Media/SmartWatch/2.png"),
    title: "SMARTWATCH PREMIUM ga harus mahal, Xiaomi sudah membuktikan!",
    category: "smartwatch",
    comment: 430,
    views: 436+"K",
    likes: 3.9+"K",
    source: "https://www.youtube.com/embed/CN_6Fbn7Xzo",
    desc: "Jam pintar yang oke dari Xiaomi. Spesifikasi PREMIUM harga MINIMUM!\n\n▶️ WHERE TO BUY\nTokopedia : https://invol.co/clb4p1l\n\n▶️ CONTACT US\nEmail : mouldie@sobathape.id\nFacebook : https://facebook.com/sobathapeofficial\nInstagram : https://instagram.com/sobat_hape\nTwitter : https://twitter.com/sobathape\n▶️ SUPPORT US\nBuat jajan nemenin bikin video : https://saweria.co/sobathape\nTraktir kita gemblong : https://trakteer.id/sobathape\nJajanin kita kopi : https://ko-fi.com/sobathape\n\n▶️ MUSIC\nEpidemicsound : https://www.epidemicsound.com/\n\n#REVIEW #XIAOMI #WATCHS1ACTIVE",
    type: "smartwatch",
    creator: "Sobat HAPE",
    subscribers: 1.04+"M",
    release: "11 Juni 2022",
    duration: "4 min 38 detik",
    quality: "HD",
},
{id: "s3",
    idDetail: "/s3",
    watchingId: `watching:s3`,
    img: require("../img/Media/SmartWatch/3.png"),
    title: "Smartwatch TERGAGAH & Ganteng dari Amazfit - T Rex 2",
    category: "smartwatch",
    comment: 1.1+"K",
    views: 1.021+"M",
    likes: 15+"K",
    source: "https://www.youtube.com/embed/18MzWWNhEBE",
    desc: "Sayangi gadget anda.\n\nKalau minat sama Amazfit T Rex 2: https://bit.ly/3SrPGQO\n\nBarang bekas review biasanya saya jual disini: https://invol.co/cl75vrb\n\nInstagram GadgetIn: https://www.instagram.com/gadgetins/\nTwitter GadgetIn: https://twitter.com/DGadgetIn\nFacebook GadgetIn: https://www.facebook.com/DgadgetIn\n\nEmail untuk kerjasama: davidbrendi88@gmail.com",
    type: "Smartwatch",
    creator: "GadgetIn",
    subscribers: 10.3+"M",
    release: "09 Agustus 2022",
    duration: "9 min 22 detik",
    quality: "HD",
},
{id: "s4",
    idDetail: "/s4",
    watchingId: `watching:s4`,
    img: require("../img/Media/SmartWatch/4.png"),
    title: "Banyak yang beli karena tersugesti‼️ SMARTWATCH T500 murah dan berkualitas",
    category: "smartwatch",
    comment: 218,
    views: 215+"K",
    likes: 2+"K",
    source: "https://www.youtube.com/embed/1uQYvdunQeg",
    desc: 'Smartwatch T500 ini kayaknya udah pantes disebut "Legend" karena rekor penjualannya yang fantastis\n\nLink Pembelian\n⬇️⬇️⬇️⬇️⬇️⬇️\nShopee : https://shope.ee/A9cCYWf8E6\n\n\nFACEBOOK Sebatang Pohon ⤵️⤵️\nhttps://www.facebook.com/sebatangpoho...\n\nemail untuk kerjasama : sebatangpohon.official@gmail.com\n\n#smartwatcht500',
    type: "smartwatch",
    creator: "Sebatang Pohon",
    subscribers: 23+"K",
    release: "01 April 2022",
    duration: "12 min 56 detik",
    quality: "HD",
}

];


export default products;