
import "../styles/NavigationBar.css"
import { Container, Nav, Navbar, NavDropdown, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import products from "../data/ProductData";

const NavigationBar = () => {
    const [content, setContent] = useState(products);
    const [searchTerm, setSearchTerm] = useState('');

    function handleChange(event){
        setSearchTerm(event.target.value);
    }
    return (
        <header>
            <Navbar variant="dark" className="nav">
                <Container>
                    <Navbar.Brand href="/dashboard" className="nav-logo"><span className="text-white">REV</span><span className="text-danger">ET</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Nav className="nav-links-font">
                        <Nav.Link href="/" className="nav-links">DASHBOARD</Nav.Link>
                        <NavDropdown id="nav-dropdown" title="CATEGORY" menuVariant="light">
                            <NavDropdown.Item href="/category/handphone">Handphone</NavDropdown.Item>
                            <NavDropdown.Item href="/category/laptop">Laptop</NavDropdown.Item>
                            <NavDropdown.Item href="/category/smartwatch">Smartwatch</NavDropdown.Item>
                            <NavDropdown.Item href="/category/earphone">Earphone</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="nav-links">ABOUT US</Nav.Link>
                        <Nav.Link className="nav-links">CONTACT</Nav.Link>
                    </Nav>
                    <Nav>
                        <SearchBar handleChange={handleChange}/>
                    </Nav>
                </Container>
            </Navbar>
        </header>
    )
}

function SearchBar({handleChange}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
  
    function handleInput(event) {
      setSearchTerm(event.target.value);
      // call the function to get the search results and update the state
      getSearchResults(searchTerm);
    }

    function handleClick() {
        setIsOpen(!isOpen);
    }
  
    function getSearchResults(term) {
      // here you can make a call to an API or filter the data from your local state
      const filteredResults = products.filter(item => item.title.toLowerCase().includes(term.toLowerCase()));
      setResults(filteredResults);
    }

    function limitWords(text, maxWords) {
        const words = text.split(' ');
        if (words.length > maxWords) {
          return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    }
    
    
    return (
        <div>
            
            <Form className="d-flex">
                <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleInput}
                    />
                <Button variant="outline-success" onClick={handleClick}><FontAwesomeIcon icon={faSearch}/></Button>
                <Link to={"/login"}><FontAwesomeIcon icon={faUser} style={{height: "25px", margin: "5px 0 0 8px"}}/></Link> 
            </Form>
            <>
        {isOpen && (
            <div className="search__dropdown">
                {results.filter((val)=>{
                    if(searchTerm == ""){
                        return val;
                    }else if(val.title.toLowerCase().includes(searchTerm.toLowerCase())){
                        return val;
                    }
                })
                .map((item)=>(
                    <NavDropdown.Item href={`/product/${item.id}`}>{limitWords(item.title, 4)}</NavDropdown.Item>
                ))}
            </div>
        )}
        </>
        </div>
    );
  }

export default NavigationBar;