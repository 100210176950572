import '../styles/LandingPage.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCommentDots, faEye, faHome, faSquare } from "@fortawesome/free-solid-svg-icons";
import {Col, Container, Image, Row} from "react-bootstrap"
import {Link, useParams } from 'react-router-dom';

import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import products from '../data/ProductData';
import categories from '../data/Category';
import Breadcrumb from '../components/Breadcrumb';

const Category = () => {


    return (
        <div className="myBG">
            <NavigationBar/>
            
            <CategoryContent/>

            <Footer/>
        </div>
    )
}

const CategoryContent =()=> {
    // const {productsId} = useParams();
    // const product = products.find((product)=>product);
    // const {watchingId, img, title, category, source, desc, views, comment,
    //     type,creator, subscribers, release,view, likes, duration, quality} = product;

    const {categoryId} = useParams();
    const Categories = categories.find((Categories)=>Categories.id === categoryId);
    const {id} = Categories;

    return (            
    <section className='section'>
        <Container>
            <Row>
                <Col>
                    <div className="breadcrumb__links">
                        <a href="/" className="breadcrumb__logo"><FontAwesomeIcon icon={faHome}/> Beranda <span><FontAwesomeIcon icon={faChevronRight} className="me-1"/></span></a>
                        <span>{id}</span>
                    </div>
                </Col>
            </Row>

            <Row className="mb-5 mt-4">
            {products.filter((data)=> data.category === id).map((data)=>(
                <Col xs={4}>
                    <div className="product__item">
                        <div className="product__item__pic set-bg" key={data.id}>
                            <Link to={`/product/${data.id}`}>
                                <Image 
                                    className="product__item__pic set-bg"
                                    src= {data.img} 
                                    alt=""
                                    width={415}
                                    height={100}
                                />
                            </Link>
                            <div className="comment">{data.comment}</div>
                            <div className="view">{data.views}</div>
                        </div>
                        <div className="product__item__text mt-3">
                            <div className="news-carousel-category ">
                                <Link to={`/category/${data.category}`}>{data.category}</Link>
                            </div>
                            <h5 className="mt-3"><Link to={`/${data.id}`}>{data.title}</Link></h5>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>

        </Container>
    </section>)
}


export default Category;