import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import products from "../data/ProductData.js";
import categories from "../data/Category.js";

function Product(){
    return (
        <section className='product'>
            <Container>
                <Row>
                    <Col xs={8}>
                        <ProductTitle 
                            title="Handphone"
                            categoryId="handphone"
                            isMostViewed = {true}
                            widget={<ProductContent category="handphone"/>}
                        />
                        <ProductTitle 
                            title="Laptop"
                            categoryId="laptop"
                            isMostViewed = {true}
                            widget={<ProductContent category="laptop"/>}
                        />
                        <ProductTitle 
                            title="Smartwatch"
                            categoryId="smartwatch"
                            isMostViewed = {true}
                            widget={<ProductContent category="smartwatch"/>}
                        />
                        <ProductTitle 
                            title="Earphone"
                            categoryId="earphone"
                            isMostViewed = {true}
                            widget={<ProductContent category="earphone"/>}
                        />
                    </Col>
                    <Col xs={4}>
                        <ProductTitle 
                        // categoryId="earphone"
                        title="Most Viewed" 
                        col1={8}
                        isMostViewed = {false}
                        widget={<MostViewed />}
                        />
                        <ProductTitle title="Comment" col1={8} detail={<p></p>}/>
                    </Col>
                </Row>
            </Container>
        </section>
        
    )
}

function ProductTitle(props){
    return (
        <>
        {props.isMostViewed ?
        <>
        {categories.filter((data)=> data.id === props.categoryId).map((data)=>(
            <Col xs={12}>
                <Row>
                    <Col xs={props.col1 ?? 10}>
                        <div className="section-title">
                            <h4>{props.title}</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className="section-title-right">
                            { props.detail ?? <a href={`/category/${data.id}`} className="primary-btn">Lihat Semua <FontAwesomeIcon icon={faArrowRight}/></a>}
                        </div>
                    </Col>
                </Row>
                {props.widget}
            </Col>
        ))}
        </>
        : <Col xs={12}>
        <Row>
            <Col xs={props.col1 ?? 10}>
                <div className="section-title">
                    <h4>{props.title}</h4>
                </div>
            </Col>
            <Col>
                <div className="section-title-right">
                    { props.detail ?? <a className="primary-btn">Lihat Semua <FontAwesomeIcon icon={faArrowRight}/></a>}
                </div>
            </Col>
        </Row>
        {props.widget}
    </Col>
        }
       </>
    )
}

function ProductContent(props){
    return (
        <Row className="mb-5">
            
            {products.filter((data, index)=> props.isMostViewed ? index < 1 : data.category === props.category).map((data)=>(
                <Col xs={props.col ?? 6}>
                    <div className="product__item">
                        <div className="product__item__pic set-bg" key={data.id}>
                            <Link to={`/product/${data.id}`}>
                                <Image 
                                    className="product__item__pic set-bg"
                                    src= {data.img} 
                                    alt=""
                                    width={415}
                                    height={100}
                                />
                            </Link>
                            <div className="comment">{data.comment}</div>
                            <div className="view">{data.views}</div>
                        </div>
                        <div className="product__item__text mt-3">
                            <div className="news-carousel-category ">
                                <Link to={`/category/${data.category}`}>{data.category}</Link>
                            </div>
                            <h5 className="mt-3"><Link to={`/${data.id}`}>{data.title}</Link></h5>
                        </div>
                    </div>
                </Col>
            ))} 
        </Row>
    )
}

function MostViewed(props){
    return(
        <Row className="mb-5">
            
            {products.filter((data, index)=>index < 3 ).map((data)=>(
                <Col xs={12}>
                    <div className="product__item">
                        <div className="product__item__pic set-bg" key={data.id}>
                            <Link to={`/product/${data.id}`}>
                                <Image 
                                    className="product__item__pic set-bg"
                                    src= {data.img} 
                                    alt=""
                                    width={415}
                                    height={100}
                                />
                            </Link>
                            <div className="comment">{data.comment}</div>
                            <div className="view">{data.views}</div>
                        </div>
                        <div className="product__item__text mt-3">
                            <div className="news-carousel-category ">
                                <Link to={`/category/${data.category}`}>{data.category}</Link>
                            </div>
                            <h5 className="mt-3"><Link to={`/${data.id}`}>{data.title}</Link></h5>
                        </div>
                    </div>
                </Col>
            ))} 
        </Row>
    )
}

export default Product;

